<template>
<v-data-table class="elevation-1" :items="data" :headers="computedHeaders" :loading="tableLoading">
    <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="edit(item)">
            mdi-pencil
        </v-icon>
        <v-icon small @click="remove(item)">
            mdi-delete
        </v-icon>
        <v-icon small @click="sendMail(item)">
            mdi-mail
        </v-icon>
        <v-icon small @click="open(item)">
            mdi-open-in-new
        </v-icon>
    </template>
    <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Recursos Humanos de TIC</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Novo</v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline">Recursos Humanos de TIC</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-row v-if="$state.role != 'eqpdtiuo'">
                                        <UOAutoComplete v-model="Item.uo" :error-messages="errors['uo']" />
                                    </v-row>
                                    <v-row>
                                        <v-text-field v-model="Item.username" label="Matrícula SIAPE" @input="isTyping = true" :error-messages="errors['username']"></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field v-model="Item.nome" label="Nome" :error-messages="errors['nome']"></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field v-model="Item.email" label="Email" :error-messages="errors['email']"></v-text-field>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn :loading="saving" color="darken-1" text @click="save">Salvar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
    </template>
</v-data-table>
</template>

<script>
import {
    RecursoHumano
} from "@/api/eqpdti";
import _ from "lodash";
import UOAutoComplete from "@/components/UOAutoComplete";
export default {
    name: "RecursoHumano",
    components: {
        UOAutoComplete,
    },
    data: () => ({
        data: [],
        headers: [{
                text: "Matrícula SIAPE",
                value: "username",
                align: "center",
            },
            {
                text: "Nome",
                value: "nome",
                align: "left",
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false,
                align: "end",
            },
        ],
        Item: {},
        dialog: false,
        saving: false,
        tableLoading: false,
        isTyping: false,
        errors: [],
    }),
    watch: {
        "Item.username": _.debounce(function () {
            this.isTyping = false;
        }, 500),
        isTyping: function (value) {
            if (!value) {
                RecursoHumano.search(this.Item.username).then(({
                    data
                }) => {
                    let item = {
                        matricula: this.Item.username,
                        nome: `${data.first_name} ${data.last_name}`,
                        email: data.email,
                    };
                    this.Item = Object.assign(item, this.Item);
                    this.$forceUpdate();
                });
            }
        },
    },
    computed: {
        computedHeaders() {
            this.fetch();
            if (this.$state.role != "eqpdtiuo") {
                return [{
                    text: "Unidade Organizacional",
                    value: "uo_sigla",
                    align: "center",
                }, ].concat(this.headers);
            }
            return this.headers;
        },
    },
    methods: {
        open(item) {
            window.open(this.getURL(item.uid), "_blank");
        },
        sendMail(item) {
            RecursoHumano.sendMail(item.id).then(() => {
                this.$emitter.emit("alert:success", "Email enviado.");
            });
        },
        getURL(uid) {
            let url = process.env.VUE_APP_BASE_URL.replace(/\/$/, "");
            return `${url}/perfil/${uid}`;
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.Item = {};
            });
        },
        save() {
            this.saving = true;
            this.errors = [];
            const _then = () => {
                this.saving = false;
                this.close();
                this.fetch();
            };
            const _catch = (error) => {
                this.saving = false;
                this.errors = error.response.data;
                if (this.errors["non_field_errors"]) {
                    this.$emitter.emit("alert:error", this.errors["non_field_errors"][0]);
                } else {
                    this.$emitter.emit("alert:error", this.errors);
                }
            };

            if (this.Item["id"]) {
                let id = this.Item["id"];

                RecursoHumano.update(id, this.Item)
                    .then(_then)
                    .catch(_catch);
            } else {
                RecursoHumano.post(this.Item)
                    .then(_then)
                    .catch(_catch);
            }
        },
        edit(item) {
            this.Item = Object.assign({}, item);
            this.dialog = true;
        },

        remove(item) {
            if (window.confirm("Deseja realmente excluir?")) {
            this.tableLoading = true;
            RecursoHumano.remove(item["id"]).then(() => {
                this.tableLoading = false;
                this.fetch();
            });
            }
        },
        fetch() {
            this.tableLoading = true;
            RecursoHumano.list().then((response) => {
                this.data = response.data;
                this.data = this.data.map(i => {
                    i.username = i.matricula;
                    return i
                })
                this.tableLoading = false;
            });
        },
    },
    // mounted() {
    //     this.fetch();
    // },
};
</script>
